* {
  font-family: 'Verdana' !important;
  color: #06193D;
}

.signin-label {
  font-size: larger;
  padding: 20px;
  text-align: center;
  font-weight: bolder;
}

.input-container {
  /*background-color: #F6F6F9;*/
  border-width: 1px;
  padding: 10px;
}

.input-container-background {
  padding: 5px;
  margin-bottom: 10px;
  background-color: #F6F6F9;
}

.signup-input-error {
  font-weight: 500;
}

.signup-input-error>img {
  width: 20px;
  height: 20px;
}

.link-text {
  /*position: absolute;
  right: 10px;*/
  text-decoration: underline;
  cursor: pointer;
}

.btn-primary {
  background-color: #0052C2;
}

.btn-outline-primary {
  border-color: #0052C2;
}

.btn-primary.disabled {
  background-color: #0052C2;
  opacity: .5;
  cursor: not-allowed;
}

.header-container {
  background-color: #EBF4FB;
  padding: 10px;
  font-size: 20px;
}

.bg-blue {
  background-color: #023B89;
  color: white;
}

.main-footer {
  background-color: #023B89;
  color: white;
  padding: 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.create-account-step-label {
  padding: 15px;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #D7DBE0;
}

.text-block {
  padding: 15px;
}

.create-account-button-container {
  width: 100%;
  display: inline-block;
  text-align: center;
  position: fixed;
  bottom: 20%;
}

.input-container>.btn {
  margin-top: 10px;
}

.create-account-button-container>.btn {
  margin-left: 15px;
  margin-right: 15px;
  width: 145px;
  height: 40px;
}

.step-title {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-size: large;
}

.complete-container {
  background-color: #F6F6F9;
  margin-top: 10%;
  margin-bottom: 5%;
  text-align: center;
  padding: 50px;
}

.code-expired-container {
  margin-top: 10%;
  margin-bottom: 5%;
  text-align: center;
  padding: 50px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.ecc-field-label {
  font-weight: 500;
}

a {
  color: #0052C2;
  font-weight: 500;
}

.ecc-field-link {
  color: #0052C2;
  font-weight: 500;
}

.code-expired-container-text {
  font-size: larger;
  font-weight: bolder;
}

.code-expired-container-subtext {
  margin-top: 20px;
  font-size: medium;
}

.complete-container-text {
  color: #027A83;
  font-size: larger;
}

.complete-container-subtext {
  color: #465870;
  font-size: 14px;
}

.block-button-container {
  padding: 10px;
}

.block-button-container>.btn {
  height: 48px;
}

.app-header-bar {
  padding: 5px;
  padding-left: 10px;
  padding-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #D8D8D8;
}

.app-header-bar-nav-container {
  padding: 10px;

}

.signup-input-error {
  color: #f00
}

.complete-container-success>img {
  width: 6rem;
  margin-bottom: 5px;
}

.input-label-container {
  display: inline-flex;
  width: 100%;
}

.signin-logo-image {
  height: 50px;
}

.error-container {
  color: #CA4C21;
  margin: 10px;
  font-weight: 500;
}

.error-container-border {
  padding: 20px;
  border-style: solid;
  background-color: #f8e9e4;
  border-radius: 3px;
}



.error-container>img {
  margin-top: 3px;
  margin-right: 10px;
  height: 20px;
}

.success-container {
  margin: 10px;
  padding: 20px;
  border-style: solid;
  background-color: lightgreen;
  color: green;
  border-radius: 3px;
}


.success-container>img {
  margin-right: 10px;
  height: 20px;
}

.additional-info-label {
  font-size: smaller;
}

.signin-tou-container {
  padding: 20px;
  font-size: smaller;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #D8D8D8;
}

.signin-info-container {
  padding: 20px;
  font-size: medium;
}

.signin-create-account-container {
  padding: 20px;
}

.signin-parent-container {
  margin-bottom: 100px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%
}

.phone-input-container {
  display: flex;
}

.vertical-bar {
  border-right-color: #ced4da;
  border-right-style: solid;
}

.phone-input-container input,
.phone-input-container select {
  color: #495057;
  padding-left: 10px;
  margin-right: 5px;
}

.phone-input-container input:focus,
.phone-input-container select:focus {
  outline: none;
}

.signin-subtitle-text {
  font-size: large;
  font-weight: bold;
}

.signin-provider-label {
  font-size: large;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding-top: 10px;
}


@media only screen and (max-width: 600px) {
  .signin-parent-container {
    overflow-y: scroll;
  }
}

@media only screen and (min-width: 600px) {
  .app-root {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .signin-parent-container {
    height: 70%;
    margin: 10px;
    /* padding: 5px; */
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .input-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .input-container,
  .signin-tou-container,
  .signin-info-container,
  .signin-create-account-container {
    padding-right: 10%;
    padding-left: 10%;
  }

  .step-title {
    min-width: 600px;
  }
}

@media only screen and (min-width: 800px) {
  .step-title {
    width: 100%;
    min-width: 800px;
  }

  .create-account-main-container {
    padding-left: 10%;
    padding-right: 10%;
  }

  .create-account-button-container {
    width: calc(100% - 20%)
  }

}

.overlayImg {
  text-align: center;
  padding-top: 30%;
  position: absolute;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0,0,0,0.2);*/
  z-index: 2;
  left: 0;
  top: 0;
}

.create-account-main-container {
  width: 100%;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.app-root {
  padding-bottom: 100px;
}

.dimmed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /*bottom: 0;*/
  height: 50vh;
  /*vertical-align: middle;*/
  z-index: 100;
  background-color: white;
  opacity: 0.90;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.dimmed>div {
  opacity: 1.0;
}

.dimmed>img {
  align-self: center;
  opacity: 1.0;
}

.signin-expired-modal .modal-content {
  margin-top: 50px;
  height: 400px;
}

.signin-expired-container {
  padding-top: 40px;
}

.signin-expired-title {
  text-align: center;
  font-size: x-large;
  font-weight: bold;
}

.signin-expired-body {
  font-size: large;
  text-align: center;
  color: #06193D;
}

.signin-expired-button-container {
  position: absolute;
  bottom: 50px;
  left: 20px;
  right: 20px;
  color: #06193D;
}

.background-block {
  background-color: #F6F6F9;
}

.block-border-top {
  border-top-color: #eaecee;
  border-top-style: solid;
  border-top-width: 1px;
}

.standard-text {
  color: #061a3d
}

.subtitle-margin {
  margin-top: 10px;
  margin-bottom: 10px;
}


.modal-body-text-block {
  padding-top: 20px;
  padding-bottom: 20px;
}

.modal-footer {
  justify-content: unset;
}

.alert {
  font-size: medium;
}

.alert-heading {
  font-size: medium;
}

.container {
  padding-right: 0px;
  padding-left: 0px;
}

@media only screen and (max-width: 1200px) {
  .container {
    max-width: unset;
  }
}

.ecc-text {
  color: #06193D;
}

.ecc-success-text {
  color: #027A83;
}